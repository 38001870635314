<template>
  <div>
    <b-card title="Danışman Poliçe Dağılımı">
      <b-row>
        <b-col
          v-for="(item,key) in dataItem"
          :key="key"
          cols="12"
          md="3"
          class="text-center mb-2"
        >
          <div class="bg-light-warning rounded py-1">
            <div class="font-weight-bold text-primary">
              {{ item.username }}
            </div>
            <div class="font-weight-light font-medium-2">
              {{ item.count | toNumber }}
              <div class="font-weight-bold text-danger font-small-4">
                {{ item.opens |toNumber }} Adet Beklemede
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'

export default {
  name: 'Summary',
  components: {
    BCard, BRow, BCol,
  },
  props: {
    dataItem: {
      type: Array,
      required: true,
    },
  },
}
</script>
