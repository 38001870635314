<template>
  <div>
    <loading v-if="dataLoading" />
    <template v-else>
      <summary-card
        :data-item="dataItem.summary"
      />
      <users-card :data-item="dataItem.users" />
      <details-card
        :data-list="dataItem.details"
        :user-list="dataItem.users"
      />
    </template>
  </div>
</template>
<script>
import SummaryCard from '@/views/Insurance/Renewal/Periods/View/Summary.vue'
import UsersCard from '@/views/Insurance/Renewal/Periods/View/Users.vue'
import DetailsCard from '@/views/Insurance/Renewal/Periods/View/Details.vue'
import Loading from '@/layouts/components/Loading.vue'

export default {
  name: 'View',
  components: {
    SummaryCard,
    UsersCard,
    DetailsCard,
    Loading,
  },
  computed: {
    dataItem() {
      return this.$store.getters['insuranceRenewalPeriods/dataItem']
    },
    dataLoading() {
      return this.$store.getters['insuranceRenewalPeriods/getLoading']
    },
    saveStatus() {
      return this.$store.getters['insuranceRenewals/dataSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.getDataList()
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.getData()
    },
  },
  created() {
    this.getData()
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    getData() {
      this.$store.dispatch('insuranceRenewalPeriods/getDataItem', this.$route.params.id)
    },
  },
}
</script>
