<template>
  <div>
    <b-card title="Aktarım Özeti">
      <b-row>
        <b-col
          cols="12"
          md="3"
          class="text-center"
        >
          <div class="font-weight-bold text-primary">
            Dönem Adı
          </div>
          <div class="font-weight-light font-medium-2">
            {{ dataItem.title }}
          </div>
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="text-center"
        >
          <div class="font-weight-bold text-primary">
            Durum
          </div>
          <div class="font-weight-light font-medium-2">
            {{ dataItem.status === '1'? 'Aktif' : 'Pasif' }}
          </div>
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="text-center"
        >
          <div class="font-weight-bold text-primary">
            Toplam Poliçe
          </div>
          <div class="font-weight-light font-medium-2">
            {{ dataItem.totals | toNumber }}
          </div>
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="text-center"
        >
          <div class="font-weight-bold text-primary">
            Aktarım
          </div>
          <div>
            <div class="font-weight-light font-medium-2">
              {{ dataItem.username }}
            </div>
            <div class="font-small-2 text-warning">
              {{ moment(dataItem.created).format('LL') }}
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'

export default {
  name: 'Summary',
  components: {
    BCard, BRow, BCol,
  },
  props: {
    dataItem: {
      type: Object,
      required: true,
    },
  },
}
</script>
