<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Yenileme Listesi Detay</b-card-title>
      </b-card-header>
      <b-card-body>
        <b-form-group
          label="Arama"
          label-for="search"
        >
          <b-input-group>
            <b-form-input
              id="search"
              v-model="search"
              placeholder="Arama..."
              @keydown.enter="searchData"
            />
            <b-input-group-append>
              <b-button
                variant="primary"
                @click="searchData"
              >
                <FeatherIcon icon="SearchIcon" /></b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-alert show>
          <div class="alert-body">
            <p>
              <FeatherIcon icon="InfoIcon" /> Arama alanı, temsilci adı, müşteri adı, poliçe no ve plaka alanlarında arama yapmaktadır.
            </p>
            <p>
              <FeatherIcon icon="InfoIcon" /> Arama sonuçları 50 adet ile limitli gösterilmektedir.
            </p>
          </div>
        </b-alert>
      </b-card-body>
      <vue-perfect-scrollbar
        v-if="dataList.length > 0"
        class="scroll-area"
        :settings="settings"
      >
        <b-table
          responsive="sm"
          :fields="fields"
          :items="dataList"
          striped
          small
        >
          <template #cell(id)="data">
            <b-button
              class="btn-icon"
              pill
              variant="flat-warning"
              size="sm"
              @click="getLine(data.item.id)"
            >
              <FeatherIcon icon="EditIcon" />
            </b-button>
          </template>
          <template #cell(bitis_tarihi)="data">
            {{ moment(data.item.bitis_tarihi).format('DD.MM.YYYY') }}
          </template>
          <template #cell(gecen_yil_prim)="data">
            {{ data.item.gecen_yil_prim | toCurrency }} ₺
          </template>
        </b-table>
      </vue-perfect-scrollbar>
    </b-card>
    <b-modal
      v-model="showModal"
      title="Güncelle"
      ok-only
      centered
      size="lg"
      hide-footer
      no-close-on-backdrop
    >
      <b-card-text>
        <b-list-group>
          <b-list-group-item>
            <div class="font-weight-bold text-primary">
              Şirket
            </div>
            <div>
              {{ dataLine.sirket }}
            </div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="font-weight-bold text-primary">
              TC
            </div>
            <div>
              {{ dataLine.tckn }}
            </div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="font-weight-bold text-primary">
              Müşteri
            </div>
            <div>
              {{ dataLine.musteri }}
            </div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="font-weight-bold text-primary">
              Branş
            </div>
            <div>
              {{ dataLine.brans }}
            </div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="font-weight-bold text-primary">
              Poliçe No
            </div>
            <div>
              {{ dataLine.police_no }}
            </div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="font-weight-bold text-primary">
              G. Yıl Prim
            </div>
            <div>
              {{ dataLine.gecen_yil_prim | toCurrency }}
            </div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="font-weight-bold text-primary">
              Bitiş Tarihi
            </div>
            <div>
              {{ moment(dataLine.bitis_tarihi).format('DD.MM.YYYY') }}
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-card-text>
      <hr>
      <b-form-group
        label="Temsilci"
        label-for="id_com_user"
      >
        <v-select
          id="id_com_user"
          v-model="dataLine.id_com_user"
          :options="userList"
          label="username"
          :reduce="item => item.id"
          placeholder="Temsilci"
        />
      </b-form-group>
      <div class="text-right">
        <b-button
          variant="success"
          @click="saveLine"
        >
          Kaydet
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BCardText,
  BListGroup,
  BListGroupItem,
  BTable,
  BButton,
  BModal,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BAlert,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from 'vue-select'

export default {
  name: 'Details',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardText,
    BListGroup,
    BListGroupItem,
    BTable,
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BAlert,
    VuePerfectScrollbar,
    vSelect,
  },
  props: {
    userList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      search: null,
      settings: {
        maxScrollbarLength: 60,
      },
      fields: [
        {
          key: 'id',
          label: 'ID',
          thClass: 'font-small-2 table-middle text-nowrap',
          tdClass: 'font-small-2 text-nowrap',
        },
        {
          key: 'username',
          label: 'Temsilci',
          thClass: 'font-small-2 table-middle text-nowrap',
          tdClass: 'font-small-2 text-nowrap',
        },
        {
          key: 'sirket',
          label: 'Şirket',
          thClass: 'font-small-2 table-middle text-nowrap',
          tdClass: 'font-small-2 text-nowrap',
        },
        {
          key: 'musteri',
          label: 'Müşteri',
          thClass: 'font-small-2 table-middle text-nowrap',
          tdClass: 'font-small-2 text-nowrap',
        },
        {
          key: 'brans',
          label: 'Branş',
          thClass: 'font-small-2 table-middle text-nowrap',
          tdClass: 'font-small-2 text-nowrap',
        },
        {
          key: 'police_no',
          label: 'Poliçe No',
          thClass: 'font-small-2 table-middle text-nowrap',
          tdClass: 'font-small-2 text-nowrap',
        },
        {
          key: 'gecen_yil_prim',
          label: 'GEÇEN YIL PRİM',
          thClass: 'font-small-2 table-middle text-nowrap',
          tdClass: 'font-small-2 text-nowrap',
        },
        {
          key: 'bitis_tarihi',
          label: 'BİTİŞ TARİHİ',
          thClass: 'font-small-2 table-middle text-nowrap',
          tdClass: 'font-small-2 text-nowrap',
        },
        {
          key: 'plaka',
          label: 'PLAKA',
          thClass: 'font-small-2 table-middle text-nowrap',
          tdClass: 'font-small-2 text-nowrap',
        },
        {
          key: 'brand',
          label: 'MARKA',
          thClass: 'font-small-2 table-middle text-nowrap',
          tdClass: 'font-small-2 text-nowrap',
        },
      ],
    }
  },
  computed: {
    dataLine() {
      return this.$store.getters['insuranceRenewalPeriods/dataLine']
    },
    dataList() {
      return this.$store.getters['insuranceRenewals/dataList']
    },
  },
  methods: {
    searchData() {
      this.$store.dispatch('insuranceRenewals/getDataList', {
        where: {
          'com_insurance_renewal.id_com_insurance_renewal_periods': this.$route.params.id,
        },
        or_like: {
          'com_insurance_renewal.id': this.search,
          'com_insurance_renewal.kimin': this.search,
          'com_insurance_renewal.musteri': this.search,
          'com_insurance_renewal.police_no': this.search,
          'com_insurance_renewal.plaka': this.search,
        },
        limit: 50,
        start: 0,
      })
    },
    getLine(id) {
      this.$store.dispatch('insuranceRenewalPeriods/getDataLine', id)
        .then(res => {
          if (res) {
            this.showModal = true
          }
        })
    },
    saveLine() {
      this.$store.dispatch('insuranceRenewals/saveData', this.dataLine)
        .then(res => {
          if (res) {
            this.showModal = false
          }
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.scroll-area {
  position: relative;
  margin: auto;
  width: 100%;
  height: 600px;
}

</style>
